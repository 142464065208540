<template>
  <div>
      <h2>Calendar</h2>
        <b-button variant="outline-primary" v-b-modal.AddCalendarItem>
            <b-icon icon="plus-circle-fill"  scale="1" variant="success"></b-icon>
            Add Item to Calendar 
        </b-button>
         <b-table 
        striped hover 
        :items="items" 
        :fields="fields"
        ref="TasksList"
        v-if="items.length > 0"
        >
         <template #cell(notificationTime)="data">
           {{ getDate(new Date(data.item.notificationTime))  }} 
        </template>
        <template #cell(notificationType)="data">
           {{itemTYpes.filter(n=>n.value == data.item.notificationType)[0].text }} 
        </template>
         <template #cell(importance)="data">
           {{importanceOptions.filter(n=>n.value == data.item.importance)[0].text }} 
        </template>
          <template #cell(notificationStatus)="data">
           {{NotificationStatusOptions.filter(n=>n.value == data.item.notificationStatus)[0].text }} 
             <!-- <b-form-select 
            id="ddlItemType" 
            :options="NotificationStatusOptions">
            <template #first>
                <b-form-select-option :value="null" disabled>...</b-form-select-option>
            </template>
        </b-form-select> -->
        </template>
        <template #cell(Is_Active)="data">
            <b-form-checkbox  :checked=data.value name="check-button"  >
            </b-form-checkbox>  
        </template>
        <template #cell(Action)="row">
            <b-button size="sm" class="mr-2"  @click="editItem(row.item,row.index)" >Edit</b-button>
            <!-- <b-button size="sm" class="mr-2" variant="danger">Delete</b-button> -->
        </template>
        </b-table>
         <div v-if="items.length ==0">
            <center>
                <h2>No Items</h2>
            </center>
        </div>
        <b-modal id="AddCalendarItem" title="Add Calendar Item" hide-footer @show="Reset()" hide-backdrop size="lg">
             <form ref="form" @submit.stop.prevent="addCalendarItem">
                  <b-container fluid>
                      <b-row class="my-1"> 
                           <b-col sm="2" >
                                <label for="ddlItemType">Item Type: </label>
                            </b-col>
                             <b-col sm="6">
                                <b-form-select 
                                    id="ddlItemType" 
                                    v-model="$v.form.itemType.$model" 
                                        :state="validateState('itemType')"
                                        aria-describedby="itemType-feedback"
                                    :options="itemTYpes">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- Selct Item Type --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="itemType-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                            </b-col>
                      </b-row>
                      <b-row class="my-2">
                           <b-col sm="2" >
                                <label for="ddlItemType">Time: </label>
                            </b-col>
                           <b-col sm="10">
                                <b-input-group  class="mb-2">
                                    <b-form-datepicker
                                        placeholder="select start date"
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                        :min="min"
                                        id="startDate"
                                        name="startDate"
                                        v-model="$v.form.startDate.$model"
                                        :state="validateState('startDate')"
                                        aria-describedby="startDate-feedback">
                                    </b-form-datepicker>
                                    <b-form-invalid-feedback id="startDate-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                                    <b-form-timepicker 
                                    v-model="$v.form.startTime.$model" 
                                    :state="validateState('startTime')"
                                    aria-describedby="startTime-feedback"></b-form-timepicker>
                                    <b-form-invalid-feedback id="startTime-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                                </b-input-group>
                           </b-col>
                      </b-row>
                    <b-row class="my-2">
                         <b-col sm="2" >
                                <label for="ddlItemType">Importance: </label>
                            </b-col>
                             <b-col sm="6">
                                <b-form-select 
                                    id="ddlImportance" 
                                    v-model="form.importance" 
                                    :options="importanceOptions">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- Selct Item Type --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-col>
                    </b-row>
                     <b-row class="my-2">
                          <b-col sm="12" >
                               <b-button type="submit" variant="primary">Submit</b-button>
                          </b-col>
                     </b-row>
                   
                  </b-container>
             </form>
        </b-modal>
        <b-modal id="EditCalendarItem" title="Edit Calendar Item" hide-footer hide-backdrop size="lg">
             <form ref="form" @submit.stop.prevent="updateCalendarItem">
                 <b-form-group
                label="Id"
                label-for="Id" >
                   {{this.form.id}}
                </b-form-group>
                  <b-container fluid>
                      <b-row class="my-1"> 
                           <b-col sm="2" >
                                <label for="ddlItemType">Item Type: </label>
                            </b-col>
                             <b-col sm="6">
                                <b-form-select 
                                    id="ddlItemType" 
                                    v-model="$v.form.itemType.$model" 
                                        :state="validateState('itemType')"
                                        aria-describedby="itemType-feedback"
                                    :options="itemTYpes">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- Selct Item Type --</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback id="itemType-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                            </b-col>
                      </b-row>
                      <b-row class="my-2">
                           <b-col sm="2" >
                                <label for="ddlItemType">Time: </label>
                            </b-col>
                           <b-col sm="10">
                                <b-input-group  class="mb-2">
                                    <b-form-datepicker
                                        :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                                        :min="min"
                                        placeholder="select start date"
                                        id="startDate"
                                        name="startDate"
                                        v-model="$v.form.startDate.$model"
                                        :state="validateState('startDate')"
                                        aria-describedby="startDate-feedback">
                                    </b-form-datepicker>
                                    <b-form-invalid-feedback id="startDate-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                                    <b-form-timepicker 
                                    v-model="$v.form.startTime.$model" 
                                    :state="validateState('startTime')"
                                    aria-describedby="startTime-feedback">
                                    </b-form-timepicker>
                                    <b-form-invalid-feedback id="startTime-feedback">
                                            This is a required field
                                    </b-form-invalid-feedback>
                                </b-input-group>
                           </b-col>
                      </b-row>
                    <b-row class="my-2">
                         <b-col sm="2" >
                                <label for="ddlItemType">Importance: </label>
                            </b-col>
                             <b-col sm="6">
                                <b-form-select 
                                    id="ddlImportance" 
                                    v-model="form.importance" 
                                    :options="importanceOptions">
                                    <template #first>
                                        <b-form-select-option :value="null" disabled>-- Selct Item Type --</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-col>
                    </b-row>
                     <b-row class="my-2">
                          <b-col sm="12" >
                               <b-button type="submit" variant="primary">Submit</b-button>
                          </b-col>
                     </b-row>
                   
                  </b-container>
             </form>
        </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required} from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
     computed: {
        userId () {
            return this.$store.state.userDetails.userId;
        },
        surveyYear(){
            return this.$store.getters.surveyYear;
        },
    },
    data(){
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        return{
             min: minDate,
             fields: ['id','notificationType', 'notificationTime', 'importance',"notificationStatus","Action"],
            items: [],
            results:[],
            currentEditedItem:null,
             form: {
                id:null,
                itemType:null,
                importance:1,
                startDate:null,
                startTime:null,
                status:null,
            },
            itemTYpes:[
                {text:'System Notification',value:1},
                {text:'Task',value:2},
                {text:'Ticket',value:3},
                {text:'Meeting',value:4},
            ],
             NotificationStatusOptions:[
                {text:'Scheduled',value:1},
                {text:'InProgress',value:2},
                {text:'Done',value:3},
            ],
            sourceOptions:[
                {text:'System',value:1},
                {text:'Admin',value:2},
                {text:'Customer',value:3},
                {text:'Me',value:4},
            ],
            importanceOptions:[
                 {text:'Normal',value:1},
                {text:'High',value:2},
            ]
        }
    },
     validations: {
         form:{
             itemType:{
                 required
             },
             startDate:{
                 required
             },
             startTime:{
                 required
             },
             importance:{
                 required
             }
         }
     },
    methods:{
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
         getDate(date){
             console.log(date)
          let today = new Date();
          if(date != null){
            today = date;
          }
          var dd = String(today.getDate()).padStart(2, '0');
          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
          var yyyy = today.getFullYear();
          return  mm + '/' + dd + '/' + yyyy;
        },
        Reset(){
                this.form.id = null;
                this.form.itemType = null;
                this.form.startDate= null;
                this.form.startTime= null;
                this.form.importance= null;
                this.form.status = null;
                this.$nextTick(() => {
                this.$v.$reset();
            });
             
        },
        editItem(item,index){
            this.Reset();
            // console.log(new Date(item.notificationTime).toLocaleTimeString())
            let date = new Date(item.notificationTime)
             this.$nextTick(() => {
                this.form.id = item.id;
                this.form.itemType = item.notificationType;
                this.form.startDate= new Date(item.notificationTime);
                this.form.startTime=date.getHours() + ':' + date.getMinutes()
                this.form.importance= item.importance;
                this.form.status = item.notificationStatus;
                this.$bvModal.show('EditCalendarItem');
                this.currentEditedItem = index;
            });
        },
        updateCalendarItem(){
              this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

             this.$axios({
                    method: 'put',
                    url: `${this.$baseURL}/Notification`,
                    data: {
                        Id:this.form.id,
                        UserId: String(this.userId),
                        SurveyYear:this.surveyYear,
                        NotificationType:Number(this.form.itemType),
                        NotificationTime:new Date(this.getDate(new Date(this.form.startDate)) +' ' + this.form.startTime ).toString(),
                        Importance:this.form.importance,
                        NotificationStatus:this.form.status
                    }
                }).then((response)=>{
                    if(JSON.parse(response.data.status) == true)
                    {
                        this.items = response.data.notificationsList;
                        this.$bvModal.hide('EditCalendarItem');
                        this.$refs.TasksList.refresh();
                    }
                });
        },
        addCalendarItem(){
            // console.log(this.form.startDate );
              this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.$axios({
                    method: 'post',
                    url: `${this.$baseURL}/Notification`,
                    data: {
                        UserId: String(this.userId),
                        SurveyYear:this.surveyYear,
                        NotificationType:Number(this.form.itemType),
                        NotificationTime:new Date(this.form.startDate +' ' +  this.form.startTime ).toString(),
                        Importance:this.form.importance,
                        // NotificationStatus:''
                    }
                }).then((response)=>{
                    if(JSON.parse(response.data.status) == true){
                         this.$nextTick(() => {
                            this.$bvModal.hide('AddCalendarItem')
                        });

                        this.$bvModal.msgBoxOk('Item added successfully', {
                                                title: 'Confirmation',
                                                size: 'sm',
                                                buttonSize: 'sm',
                                                okVariant: 'success',
                                                headerClass: 'p-2 border-bottom-0',
                                                footerClass: 'p-2 border-top-0',
                                                centered: true
                                                });
                        this.items = response.data.notificationsList;
                    } 
                });
        },
        GetNotifications(){
             this.$axios({
                    method: 'get',
                    url: `${this.$baseURL}/Notification`,
                    params: {
                        UserId: this.userId,
                        SurveyYear: this.surveyYear,
                    }
                })  .then((response)=>{
               console.log(response)
                if(JSON.parse(response.data.status) == true){
                    this.items = response.data.notificationsList;
                }
                else{
                    this.items = [];
                }
            });
        }
    },
    mounted(){
        this.GetNotifications();
        // console.log("requesting data from backend.....");
        // axios.get('http://localhost:55022/API/Notification/Lookups')
        // .then(res=>{
        //     console.log(res.data);
        // })
    }
}
</script>

<style>

</style>